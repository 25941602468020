.nebula-mobile {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .nebula-mobile .overlap-wrapper {
    background-color: #ffffff;
    border: 1px none;
    height: 2050px;
    overflow: hidden;
    width: 100%;
  }

  .nebula-mobile .content-centering-container {
    width: 400px;
    height: 100%;
    left: calc(50% - 200px);
    position: relative;
  }
  
  .nebula-mobile .overlap {
    height: 1568px;
    position: relative;
    width: 100%;
  }
  
  .nebula-mobile .header-section {
    height: 975px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .nebula-mobile .overlap-group {
    background-color: #002e36;
    height: 975px;
    position: relative;
    width: 100%;
  }
  
  .nebula-mobile .compact-menu {
    height: 141px;
    left: 48px;
    position: absolute;
    top: 77px;
    width: 306px;
  }
  
  .nebula-mobile .text-wrapper {
    color: #ffd6af;
    font-family: "Montserrat", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 56px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 11px;
    width: 244px;
  }
  
  .nebula-mobile .div {
    color: #ffd6af;
    font-family: "Montserrat", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 56px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 50px;
    width: 244px;
  }
  
  .nebula-mobile .text-wrapper-2 {
    color: #ffd6af;
    font-family: "Montserrat", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 56px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 90px;
    width: 244px;
  }
  
  .nebula-mobile .FYS-logo {
    height: 141px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 39px;
  }
  
  .nebula-mobile .text-wrapper-3 {
    color: #ffd6af;
    font-family: "Montserrat", Helvetica;
    font-size: 44px;
    font-weight: 200;
    height: 78px;
    left: 48px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 225px;
    width: 295px;
  }
  
  .nebula-mobile .p {
    color: #ffd6af;
    font-family: "Montserrat", Helvetica;
    font-size: 12px;
    font-weight: 200;
    height: 408px;
    left: 48px;
    letter-spacing: 0;
    line-height: normal;
    
    position: absolute;
    top: 323px;
    width: 295px;
  }
  
  .nebula-mobile .header-bg {
    background-color: #ffd6af;
    height: 1543px;
    left: 1px;
    position: absolute;
    top: 975px;
    width: 100%;
  }
  
  .nebula-mobile .calendar-wrapper {
    height: 2500px;
    left: 47px;
    object-fit: cover;
    position: absolute;
    top: 1093px;
    width: 80%;
    font-family: "Montserrat", Helvetica;
  }
  
  .nebula-mobile .h-1 {
    color: #002e36;
    font-family: "Montserrat", Helvetica;
    font-size: 54px;
    font-weight: 200;
    height: 78px;
    left: 47px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 979px;
    width: 295px;
  }
  
.main-page-mobile {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.main-page-mobile a {
  color: #ffd6af;
  text-decoration: none;
}

.main-page-mobile .content-centering-container {
  width: 400px;
  height: 100%;
  position: relative;
  left: calc(50% - 200px);
}

.main-page-mobile .div {
  background-color: #fff;
  border: 1px;
  width: 100%;
  height: 4993px;
  position: relative;
  overflow: hidden;
}

.main-page-mobile .socials {
  width: 100%;
  height: 1065px;
  position: absolute;
  top: 3928px;
  left: 0;
}

.main-page-mobile .overlap-group {
  background-color: #002e36;
  width: 100%;
  height: 1065px;
  position: relative;
}

.main-page-mobile .text-wrapper {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: center;
  height: 37px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 954px;
  left: 101px;
}

.main-page-mobile .profile-pic {
  object-fit: cover;
  width: 125px;
  height: 125px;
  position: absolute;
  top: 172px;
  left: 132px;
}

.main-page-mobile .img {
  object-fit: cover;
  width: 125px;
  height: 125px;
  position: absolute;
  top: 768px;
  left: 132px;
}

.main-page-mobile .profile-pic-2 {
  object-fit: cover;
  width: 125px;
  height: 125px;
  position: absolute;
  top: 619px;
  left: 132px;
}

.main-page-mobile .profile-pic-3 {
  object-fit: cover;
  width: 125px;
  height: 125px;
  position: absolute;
  top: 470px;
  left: 132px;
}

.main-page-mobile .profile-pic-4 {
  object-fit: cover;
  width: 125px;
  height: 125px;
  position: absolute;
  top: 321px;
  left: 132px;
}

.main-page-mobile .text-wrapper-2 {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: center;
  height: 37px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 96px;
  left: 63px;
}

.main-page-mobile .welcome-section {
  width: 100%;
  height: 856px;
  position: absolute;
  top: 871px;
  left: 0;
}

.main-page-mobile .overlap {
  background-color: #ffd6af;
  width: 100%;
  height: 856px;
  position: relative;
}

.main-page-mobile .welcome-blurb {
  color: #002e36;
  letter-spacing: 0;
  text-align: left;
  width: 294px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 178px;
  left: 48px;
}

.main-page-mobile .text-wrapper-3 {
  color: #002e36;
  letter-spacing: 0;
  text-align: left;
  height: 66px;
  font-family: Montserrat, Helvetica;
  font-size: 54px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 55px;
  left: 48px;
}

.main-page-mobile .our-classes {
  width: 100%;
  height: 1254px;
  position: absolute;
  top: 1727px;
  left: 0;
}

.main-page-mobile .overlap-2 {
  background-color: #fff;
  width: 100%;
  height: 1254px;
  position: relative;
}

.main-page-mobile .p {
  color: #002e36;
  letter-spacing: 0;
  width: 279px;
  height: 885px;
  font-family: Montserrat, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 167px;
  left: 48px;
}

.main-page-mobile .text-wrapper-4 {
  color: #002e36;
  letter-spacing: 0;
  height: 59px;
  font-family: Montserrat, Helvetica;
  font-size: 48px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 69px;
  left: 48px;
}

.main-page-mobile .header-section {
  background-color: #002e36;
  width: 100%;
  height: 871px;
  position: absolute;
  top: 0;
  left: 0;
}

.main-page-mobile .compact-menu {
  width: 306px;
  height: 133px;
  position: absolute;
  top: 80px;
  left: 48px;
}

.main-page-mobile .text-wrapper-5 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 56px;
}

.main-page-mobile .text-wrapper-6 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 47px;
  left: 56px;
}

.main-page-mobile .text-wrapper-7 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 85px;
  left: 56px;
}

.main-page-mobile .FYS-logo {
  object-fit: cover;
  width: 39px;
  height: 133px;
  position: absolute;
  top: 0;
  left: 0;
}

.main-page-mobile .selection-group {
  width: 306px;
  height: 108px;
  position: absolute;
  top: 213px;
  left: 48px;
}

.main-page-mobile .text-wrapper-8 {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: right;
  width: 300px;
  height: 22px;
  font-family: Montserrat, Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.main-page-mobile .text-wrapper-9 {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: right;
  width: 300px;
  height: 29px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 36px;
  left: 0;
}

.main-page-mobile .text-wrapper-10 {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: right;
  width: 300px;
  height: 29px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 79px;
  left: 0;
}

.main-page-mobile .title {
  width: 283px;
  height: 334px;
  position: absolute;
  top: 371px;
  left: 48px;
}

.main-page-mobile .future-for-young {
  color: #ffd6af;
  letter-spacing: 0;
  height: 292px;
  font-family: Montserrat, Helvetica;
  font-size: 60px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.main-page-mobile .text-wrapper-11 {
  color: #ffd6af;
  letter-spacing: 0;
  height: 22px;
  font-family: Montserrat, Helvetica;
  font-size: 18px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 312px;
  left: 0;
}

.main-page-mobile .overlap-3 {
  width: 100%;
  height: 947px;
  position: absolute;
  top: 2981px;
  left: 0;
}

.main-page-mobile .parent-testimonials {
  width: 100%;
  height: 947px;
  position: absolute;
  top: 0;
  left: 0;
}

.main-page-mobile .overlap-4 {
  background-color: #f4d8c8;
  width: 100%;
  height: 947px;
  position: relative;
}

.main-page-mobile .parent-testimonials-2 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  height: 118px;
  font-family: Montserrat, Helvetica;
  font-size: 48px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 47px;
  left: 56px;
}

.main-page-mobile .text-wrapper-12 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 288px;
  height: 126px;
  font-family: Montserrat, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 212px;
  left: 56px;
}

.main-page-mobile .text-wrapper-13 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 288px;
  height: 126px;
  font-family: Montserrat, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 380px;
  left: 56px;
}

.main-page-mobile .text-wrapper-14 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 288px;
  height: 126px;
  font-family: Montserrat, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 508px;
  left: 56px;
}

.main-page-mobile .text-wrapper-15 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 288px;
  height: 126px;
  font-family: Montserrat, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 686px;
  left: 52px;
}

.compact-menu-mobile {
  width: 306px;
  height: 133px;
  position: absolute;
  top: 40px;
  left: 48px;
}

.compact-menu-mobile a {
  color: #ffd6af;
  text-decoration: none;
}

.compact-menu-mobile .about-us {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 56px;
}

.compact-menu-mobile .blog {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 47px;
  left: 56px;
}

.compact-menu-mobile .socials-link {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 85px;
  left: 56px;
}

.compact-menu-mobile .FYS-logo {
  object-fit: cover;
  width: 39px;
  height: 133px;
  position: absolute;
  top: 0;
  left: 0;
}

.compact-menu-desktop {
  width: 285px;
  height: 168px;
  position: absolute;
  top: 48px;
  left: 72px;
}

.compact-menu-desktop a {
  color: #ffd6af;
  text-decoration: none;
}

.compact-menu-desktop .aboutus-compactMenu {
  color: #ffd6af;
  letter-spacing: 0;
  width: 188px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  position: absolute;
  top: 15px;
  left: 91px;
}

.compact-menu-desktop .blog-compactMenu {
  color: #ffd6af;
  letter-spacing: 0;
  width: 109px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 65px;
  left: 91px;
}

.compact-menu-desktop .socials-compactMenu {
  color: #ffd6af;
  letter-spacing: 0;
  width: 147px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 115px;
  left: 91px;
}

.compact-menu-desktop .FYSlogo-compactMenu {
  object-fit: cover;
  width: 56px;
  height: 168px;
  position: absolute;
  top: 0;
  left: 0;
}

.social-media-menu .github-icon {
  object-fit: cover;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 320px;
  left: 250px;
}

.social-media-menu .instagram-icon {
  object-fit: cover;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 320px;
  left: 449px;
}

.social-media-menu .linkedin-icon {
  object-fit: cover;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 320px;
  left: 1040px;
}

.social-media-menu .facebook-icon {
  object-fit: cover;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 320px;
  left: 648px;
}

.social-media-menu .tiktok-icon {
  object-fit: cover;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 320px;
  left: 847px;
}

.social-media-menu-mobile .socials {
  width: 100%;
  height: 1065px;
  position: absolute;
  top: 3928px;
  left: 0;
}

.social-media-menu-mobile .content-centering-container {
  width: 400px;
  height: 100%;
  position: relative;
  left: calc(50% - 200px);
}

.social-media-menu-mobile .overlap-group {
  background-color: #002e36;
  width: 100%;
  height: 1065px;
  position: relative;
}

.social-media-menu-mobile .text-wrapper {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  height: 37px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 954px;
  left: 101px;
}

.social-media-menu-mobile .instagram-icon {
  object-fit: cover;
  width: 125px;
  height: 125px;
  position: absolute;
  top: 172px;
  left: 132px;
}

.social-media-menu-mobile .github-icon {
  object-fit: cover;
  width: 125px;
  height: 125px;
  position: absolute;
  top: 768px;
  left: 132px;
}

.social-media-menu-mobile .tiktok-icon {
  object-fit: cover;
  width: 125px;
  height: 125px;
  position: absolute;
  top: 619px;
  left: 132px;
}

.social-media-menu-mobile .linkedin-icon {
  object-fit: cover;
  width: 125px;
  height: 125px;
  position: absolute;
  top: 470px;
  left: 132px;
}

.social-media-menu-mobile .facebook-icon {
  object-fit: cover;
  width: 125px;
  height: 125px;
  position: absolute;
  top: 321px;
  left: 132px;
}

.social-media-menu-mobile .text-wrapper-2 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  height: 37px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 96px;
  left: 63px;
}

.main-page {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.main-page a {
  color: #ffd6af;
  text-decoration: none;
}

.main-page .div {
  background-color: #fff;
  border: 1px;
  width: 100%;
  height: 4490px;
  position: relative;
  overflow: hidden;
}

.main-page .overlap {
  width: 100%;
  height: 2060px;
  position: absolute;
  top: 810px;
  left: 0;
}

.main-page .our-classes {
  width: 100%;
  height: 1253px;
  position: absolute;
  top: 807px;
  left: 0;
}

.main-page .overlap-group {
  background-color: #fff;
  width: 100%;
  height: 1253px;
  position: relative;
}

.main-page .text-wrapper {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  height: 146px;
  font-family: Montserrat, Helvetica;
  font-size: 120px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 83px;
  left: 750px;
}

.main-page .lorem-ipsum-dolor {
  color: #000;
  letter-spacing: 0;
  width: 1198px;
  height: 725px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 353px;
  left: 72px;
}

.main-page .welcome-section {
  width: 100%;
  height: 810px;
  position: absolute;
  top: 0;
  left: 0;
}

.main-page .overlap-2 {
  background-color: #ffd6af;
  width: 100%;
  height: 810px;
  position: relative;
}

.main-page .text-wrapper-2 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 798px;
  height: 190px;
  font-family: Montserrat, Helvetica;
  font-size: 120px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 130px;
  left: 600px;
}

.main-page .welcome-message {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 798px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 360px;
  left: 600px;
}

.main-page .translucent-logo {
  object-fit: cover;
  stroke-width: 10px;
  opacity: .5;
  width: 340px;
  height: 1080px;
  position: absolute;
  top: 60px;
  left: 110px;
}

.main-page .header-section {
  background-color: #002e36;
  width: 100%;
  height: 810px;
  position: absolute;
  top: 0;
  left: 0;
}

.main-page .selection-group {
  width: 566px;
  height: 137px;
  position: absolute;
  top: 48px;
  right: 50px;
}

.main-page .text-wrapper-3 {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: right;
  width: 560px;
  height: 37px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-style: italic;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 0;
}

.main-page .text-wrapper-4 {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: right;
  width: 290px;
  height: 37px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 65px;
  left: 270px;
}

.main-page .text-wrapper-5 {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: right;
  width: 318px;
  height: 37px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 115px;
  left: 240px;
}

.main-page .compact-menu {
  width: 524px;
  height: 168px;
  position: absolute;
  top: 48px;
  left: 72px;
}

.main-page .text-wrapper-6 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 397px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 91px;
}

.main-page .text-wrapper-7 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 417px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 65px;
  left: 91px;
}

.main-page .text-wrapper-8 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 427px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 115px;
  left: 91px;
}

.main-page .FYS-logo {
  object-fit: cover;
  width: 56px;
  height: 168px;
  position: absolute;
  top: 0;
  left: 0;
}

.main-page .title {
  width: 1095px;
  height: 377px;
  position: absolute;
  top: 315px;
  left: 72px;
}

.main-page .future-for-young {
  color: #ffd6af;
  letter-spacing: 0;
  height: 330px;
  font-family: Montserrat, Helvetica;
  font-size: 135px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.main-page .text-wrapper-9 {
  color: #ffd6af;
  letter-spacing: 0;
  height: 37px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 340px;
  left: 0;
}

.main-page .parent-testimonials {
  width: 100%;
  height: 810px;
  position: absolute;
  top: 2870px;
  left: 0;
}

.main-page .overlap-3 {
  background-color: #f4d8cd;
  width: 100%;
  height: 810px;
  position: relative;
}

.main-page .text-wrapper-10 {
  color: #002e36;
  letter-spacing: 0;
  width: 600px;
  height: 220px;
  font-family: Montserrat, Helvetica;
  font-size: 90px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 520px;
  left: 72px;
}

.main-page .text-wrapper-11 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 575px;
  height: 120px;
  font-family: Montserrat, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 51px;
  left: 750px;
}

.main-page .text-wrapper-12 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 575px;
  height: 120px;
  font-family: Montserrat, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 233px;
  left: 750px;
}

.main-page .text-wrapper-13 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 575px;
  height: 120px;
  font-family: Montserrat, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 597px;
  left: 750px;
}

.main-page .text-wrapper-14 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 575px;
  height: 120px;
  font-family: Montserrat, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 385px;
  left: 750px;
}

.main-page .socials {
  width: 100%;
  height: 810px;
  position: absolute;
  top: 3680px;
  left: 0;
}

.main-page .overlap-4 {
  background-color: #002e36;
  width: 100%;
  height: 810px;
  position: relative;
}

.main-page .text-wrapper-15 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  height: 110px;
  font-family: Montserrat, Helvetica;
  font-size: 90px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 130px;
  left: 326px;
}

.main-page .text-wrapper-16 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  height: 55px;
  font-family: Montserrat, Helvetica;
  font-size: 45px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 535px;
  left: 178px;
}

.socials a {
  color: #fff;
  text-decoration: none;
}

.socials .text-wrapper-17 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  height: 55px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 300;
  line-height: normal;
  text-decoration: underline;
  position: absolute;
  top: 645px;
  left: 600px;
}

.about-us-page {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.about-us-page .div {
  background-color: #fff;
  border: 1px;
  width: 100%;
  height: 9872px;
  position: relative;
  overflow: hidden;
}

.about-us-page .content-centering-container {
  width: 1300px;
  height: 100%;
  position: relative;
  left: calc(50% - 650px);
}

.about-us-page .about-us-top {
  width: 100%;
  height: 810px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page .overlap-group {
  background-color: #002e36;
  width: 100%;
  height: 810px;
  position: relative;
}

.about-us-page .FYS-logo {
  object-fit: cover;
  width: 56px;
  height: 168px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page .h-1 {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: right;
  height: 146px;
  font-family: Montserrat, Helvetica;
  font-size: 120px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 59px;
  left: 700px;
}

.about-us-page .p {
  color: #ffd6af;
  letter-spacing: 0;
  width: 1107px;
  height: 264px;
  font-family: Montserrat, Helvetica;
  font-size: 32px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 382px;
  left: 72px;
}

.about-us-page .team-nebula {
  width: 100%;
  height: 2554px;
  position: absolute;
  top: 810px;
  left: 0;
}

.about-us-page .overlap {
  background-color: #ffd6af;
  width: 100%;
  height: 2554px;
  position: relative;
}

.about-us-page .overlap-group-2 {
  width: 100%;
  height: 820px;
  position: absolute;
  top: 319px;
  left: 103px;
}

.about-us-page .sample-person {
  width: 100%;
  height: 820px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page .profile-pic {
  object-fit: cover;
  width: 580px;
  height: 580px;
  position: absolute;
  top: 29px;
  left: 516px;
}

.about-us-page .text-wrapper-4 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 450px;
  height: 55px;
  font-family: Montserrat, Helvetica;
  font-size: 40px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page .lorem-ipsum-dolor {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 450px;
  height: 754px;
  font-family: Montserrat, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 66px;
  left: 0;
}

.about-us-page .overlap-2 {
  width: 100%;
  height: 820px;
  position: absolute;
  top: 1055px;
  left: 103px;
}

.about-us-page .overlap-3 {
  width: 100%;
  height: 820px;
  position: absolute;
  top: 1791px;
  left: 85px;
}

.about-us-page .sample-person-2 {
  width: 100%;
  height: 820px;
  position: absolute;
  top: 2528px;
  left: 85px;
}

.about-us-page .text-wrapper-5 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 703px;
  height: 110px;
  font-family: Montserrat, Helvetica;
  font-size: 90px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 104px;
  left: 500px;
}

.about-us-page .team-nova {
  width: 100%;
  height: 3254px;
  position: absolute;
  top: 3364px;
  left: 0;
}

.about-us-page .overlap-4 {
  background-color: #f4d8c8;
  width: 100%;
  height: 3254px;
  position: relative;
}

.about-us-page .text-wrapper-6 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 703px;
  height: 110px;
  font-family: Montserrat, Helvetica;
  font-size: 90px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 123px;
  left: 500px;
}

.about-us-page .team-founders {
  width: 100%;
  height: 3254px;
  position: absolute;
  top: 6618px;
  left: 0;
}

.about-us-page .overlap-5 {
  background-color: #76a5af;
  width: 100%;
  height: 3254px;
  position: relative;
}

.about-us-page .text-wrapper-7 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  width: 703px;
  height: 110px;
  font-family: Montserrat, Helvetica;
  font-size: 90px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 152px;
  left: 500px;
}

.about-us-page-mobile {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.about-us-page-mobile .div {
  background-color: #fff;
  border: 1px;
  width: 100%;
  height: 9906px;
  position: relative;
  overflow: hidden;
}

.about-us-page-mobile .content-centering-container {
  width: 400px;
  height: 100%;
  position: relative;
  left: calc(50% - 200px);
}

.about-us-page-mobile .header-section {
  width: 100%;
  height: 925px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page-mobile .overlap-group {
  background-color: #002e36;
  width: 100%;
  height: 925px;
  position: relative;
}

.about-us-page-mobile .compact-menu {
  width: 306px;
  height: 141px;
  position: absolute;
  top: 77px;
  left: 48px;
}

.about-us-page-mobile .text-wrapper {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 56px;
}

.about-us-page-mobile .text-wrapper-2 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 50px;
  left: 56px;
}

.about-us-page-mobile .text-wrapper-3 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 90px;
  left: 56px;
}

.about-us-page-mobile .FYS-logo {
  object-fit: cover;
  width: 39px;
  height: 141px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page-mobile .h-1 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 259px;
  height: 78px;
  font-family: Montserrat, Helvetica;
  font-size: 60px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 265px;
  left: 84px;
}

.about-us-page-mobile .p {
  color: #ffd6af;
  letter-spacing: 0;
  width: 295px;
  height: 304px;
  font-family: Montserrat, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 393px;
  left: 48px;
}

.about-us-page-mobile .founders {
  width: 100%;
  height: 3127px;
  position: absolute;
  top: 6679px;
  left: 1px;
}

.about-us-page-mobile .overlap {
  background-color: #76a5af;
  width: 100%;
  height: 3227px;
  position: relative;
}

.about-us-page-mobile .text-wrapper-4 {
  color: #002e36;
  letter-spacing: 0;
  height: 44px;
  font-family: Montserrat, Helvetica;
  font-size: 36px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 35px;
  left: 48px;
}

.about-us-page-mobile .overlap-group-2 {
  width: 299px;
  height: 688px;
  position: absolute;
  top: 103px;
  left: 48px;
}

.about-us-page-mobile .lauren, .about-us-page-mobile .siddh, .about-us-page-mobile .fiddi {
  width: 299px;
  height: 688px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page-mobile .text-wrapper-5 {
  color: #002e36;
  letter-spacing: 0;
  width: 295px;
  height: 29px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 349px;
  left: 0;
}

.about-us-page-mobile .profile-pic {
  object-fit: cover;
  width: 293px;
  height: 331px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-us-page-mobile .lorem-ipsum-dolor {
  color: #002e36;
  letter-spacing: 0;
  width: 293px;
  height: 300px;
  font-family: Montserrat, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 388px;
  left: 0;
}

.about-us-page-mobile .aric {
  width: 299px;
  height: 688px;
  position: absolute;
  top: 1577px;
  left: 48px;
}

.about-us-page-mobile .nikki {
  width: 299px;
  height: 688px;
  position: absolute;
  top: 1747px;
  left: 48px;
}

.about-us-page-mobile .annika {
  width: 299px;
  height: 688px;
  position: absolute;
  top: 1427px;
  left: 48px;
}

.about-us-page-mobile .bilal {
  width: 299px;
  height: 688px;
  position: absolute;
  top: 2478px;
  left: 48px;
}

.about-us-page-mobile .nav {
  width: 299px;
  height: 688px;
  position: absolute;
  top: 2428px;
  left: 48px;
}

.about-us-page-mobile .birinder {
  width: 299px;
  height: 688px;
  position: absolute;
  top: 929px;
  left: 48px;
}

.about-us-page-mobile .samiksha, .about-us-page-mobile .serena {
  width: 299px;
  height: 688px;
  position: absolute;
  top: 779px;
  left: 48px;
}

.about-us-page-mobile .team-nebula {
  width: 100%;
  height: 2327px;
  position: absolute;
  top: 925px;
  left: 0;
}

.about-us-page-mobile .overlap-2 {
  background-color: #ffd6af;
  width: 100%;
  height: 2327px;
  position: relative;
}

.about-us-page-mobile .team-nova {
  width: 100%;
  height: 3427px;
  position: absolute;
  top: 3252px;
  left: 1px;
}

.about-us-page-mobile .overlap-3 {
  background-color: #f4d8c8c8;
  width: 100%;
  height: 3427px;
  position: relative;
}

.blog-page {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.blog-page .div {
  background-color: #002e36;
  border: 1px;
  width: 100%;
  height: 900px;
  position: relative;
  overflow: hidden;
}

.blog-page .blog-top {
  width: 100%;
  height: 810px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .overlap-group {
  background-color: #002e36;
  width: 100%;
  height: 810px;
  position: relative;
}

.blog-page .compact-menu {
  width: 285px;
  height: 168px;
  position: absolute;
  top: 48px;
  left: 72px;
}

.blog-page .text-wrapper {
  color: #ffd6af;
  letter-spacing: 0;
  width: 188px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 91px;
}

.blog-page .text-wrapper-2 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 109px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 65px;
  left: 91px;
}

.blog-page .text-wrapper-3 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 147px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 115px;
  left: 91px;
}

.blog-page .FYS-logo {
  object-fit: cover;
  width: 56px;
  height: 168px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .h-1 {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: right;
  height: 146px;
  font-family: Montserrat, Helvetica;
  font-size: 120px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 59px;
  left: 1083px;
}

.blog-page .p {
  color: #ffd6af;
  letter-spacing: 0;
  width: 1197px;
  height: 132px;
  font-family: Montserrat, Helvetica;
  font-size: 36px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 448px;
  left: 72px;
}

.blog-page .overlap {
  background-color: #ffd6af;
  width: 100%;
  height: 1928px;
  position: absolute;
  top: 810px;
  left: 0;
}

.blog-page .sample-blog {
  width: 1273px;
  height: 820px;
  position: absolute;
  top: 102px;
  left: 85px;
}

.blog-page .profile-pic {
  object-fit: cover;
  width: 703px;
  height: 791px;
  position: absolute;
  top: 29px;
  left: 566px;
}

.blog-page .text-wrapper-4 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: 450px;
  height: 55px;
  font-family: Montserrat, Helvetica;
  font-size: 45px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page .lorem-ipsum-dolor {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  width: 450px;
  height: 754px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 66px;
  left: 0;
}

.blog-page .sample-blog-2 {
  width: 1273px;
  height: 820px;
  position: absolute;
  top: 1027px;
  left: 85px;
}

.blog-page-mobile {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.blog-page-mobile .overlap-wrapper {
  background-color: #fff;
  border: 1px;
  width: 100%;
  height: 900px;
  overflow: hidden;
}

.blog-page-mobile .content-centering-container {
  width: 400px;
  height: 100%;
  position: relative;
  left: calc(50% - 200px);
}

.blog-page-mobile .overlap {
  width: 100%;
  height: 3986px;
  position: relative;
}

.blog-page-mobile .header-section {
  width: 100%;
  height: 925px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page-mobile .overlap-group {
  background-color: #002e36;
  width: 100%;
  height: 925px;
  position: relative;
}

.blog-page-mobile .compact-menu {
  width: 306px;
  height: 141px;
  position: absolute;
  top: 77px;
  left: 48px;
}

.blog-page-mobile .text-wrapper {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 56px;
}

.blog-page-mobile .div {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 50px;
  left: 56px;
}

.blog-page-mobile .text-wrapper-2 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 90px;
  left: 56px;
}

.blog-page-mobile .FYS-logo {
  object-fit: cover;
  width: 39px;
  height: 141px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page-mobile .h-1 {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: right;
  width: 259px;
  height: 78px;
  font-family: Montserrat, Helvetica;
  font-size: 60px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 265px;
  left: 84px;
}

.blog-page-mobile .p {
  color: #ffd6af;
  letter-spacing: 0;
  width: 295px;
  height: 304px;
  font-family: Montserrat, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 393px;
  left: 48px;
}

.blog-page-mobile .blogs {
  background-color: #ffd6af;
  width: 100%;
  height: 3061px;
  position: absolute;
  top: 925px;
  left: 0;
}

.blog-page-mobile .sample-person {
  width: 299px;
  height: 688px;
  position: absolute;
  top: 37px;
  left: 48px;
}

.blog-page-mobile .text-wrapper-3 {
  color: #002e36;
  letter-spacing: 0;
  width: 295px;
  height: 29px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 349px;
  left: 0;
}

.blog-page-mobile .profile-pic {
  object-fit: cover;
  width: 293px;
  height: 331px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-page-mobile .lorem-ipsum-dolor {
  color: #002e36;
  letter-spacing: 0;
  width: 293px;
  height: 300px;
  font-family: Montserrat, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 388px;
  left: 0;
}

.blog-page-mobile .sample-person-2 {
  width: 299px;
  height: 688px;
  position: absolute;
  top: 1511px;
  left: 48px;
}

.blog-page-mobile .sample-person-3 {
  width: 299px;
  height: 688px;
  position: absolute;
  top: 2292px;
  left: 48px;
}

.blog-page-mobile .sample-person-4 {
  width: 299px;
  height: 688px;
  position: absolute;
  top: 763px;
  left: 48px;
}

.nova {
  background-color: #002e36;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.nova .div {
  background-color: #fff;
  border: 1px;
  width: 100%;
  height: 1893px;
  position: relative;
  overflow: hidden;
}

.nova .nova-top {
  width: 100%;
  height: 810px;
  position: absolute;
  top: 0;
  left: 0;
}

.nova .overlap-group {
  background-color: #002e36;
  width: 100%;
  height: 810px;
  position: relative;
}

.nova .compact-menu {
  width: 285px;
  height: 168px;
  position: absolute;
  top: 48px;
  left: 72px;
}

.nova .text-wrapper {
  color: #ffd6af;
  letter-spacing: 0;
  width: 188px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 91px;
}

.nova .text-wrapper-2 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 109px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 65px;
  left: 91px;
}

.nova .text-wrapper-3 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 147px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 115px;
  left: 91px;
}

.nova .FYS-logo {
  object-fit: cover;
  width: 56px;
  height: 168px;
  position: absolute;
  top: 0;
  left: 0;
}

.nova .h-1 {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: right;
  height: 146px;
  font-family: Montserrat, Helvetica;
  font-size: 120px;
  font-weight: 200;
  line-height: 1;
  position: absolute;
  top: 59px;
  right: 75px;
}

.nova .p {
  color: #ffd6af;
  letter-spacing: 0;
  width: 1197px;
  height: 352px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 278px;
  left: 72px;
}

.nova .overlap {
  background-color: #ffd6af;
  width: 100%;
  height: 2483px;
  position: absolute;
  top: 810px;
  left: 0;
}

.nova .text-wrapper-4 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  height: 146px;
  font-family: Montserrat, Helvetica;
  font-size: 120px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 72px;
  left: 850px;
}

.nova .click-here {
  color: #002e36;
  letter-spacing: 0;
  text-align: left;
  height: 146px;
  font-family: Montserrat, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 145px;
  left: 156px;
}

.nova .calendar-wrapper {
  width: 1188px;
  height: 1480px;
  font-family: Montserrat, Helvetica;
  font-size: 12px;
  position: absolute;
  top: 280px;
  left: 156px;
}

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rbc-btn {
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
  overflow: visible;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rbc-calendar {
  box-sizing: border-box;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  display: flex;
}

.rbc-calendar *, .rbc-calendar :before, .rbc-calendar :after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.rbc-abs-full, .rbc-row-bg {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: #999;
}

.rbc-off-range-bg {
  background: #e6e6e6;
}

.rbc-header {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #ddd;
  flex: 1 0;
  min-height: 0;
  padding: 0 3px;
  font-size: 90%;
  font-weight: bold;
  overflow: hidden;
}

.rbc-header + .rbc-header {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-button-link {
  color: inherit;
  cursor: pointer;
  -webkit-user-select: text;
  user-select: text;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.rbc-row-content {
  -webkit-user-select: none;
  user-select: none;
  z-index: 4;
  position: relative;
}

.rbc-row-content-scrollable {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
  overflow-y: scroll;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}

.rbc-today {
  background-color: #eaf6ff;
}

.rbc-toolbar {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
}

.rbc-toolbar .rbc-toolbar-label {
  text-align: center;
  flex-grow: 1;
  padding: 0 10px;
}

.rbc-toolbar button {
  color: #373a3c;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  background: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0;
  padding: .375rem 1rem;
  line-height: normal;
  display: inline-block;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
  box-shadow: inset 0 3px 5px #00000020;
}

.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.rbc-toolbar button:focus, .rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-btn-group {
  white-space: nowrap;
  display: inline-block;
}

.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 0 4px 4px 0;
}

.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px 0 0 4px;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.rbc-btn-group button + button {
  margin-left: -1px;
}

.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}

.rbc-btn-group + .rbc-btn-group, .rbc-btn-group + button {
  margin-left: 10px;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  text-align: left;
  background-color: #3174ad;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin: 0;
  padding: 2px 5px;
}

.rbc-slot-selecting .rbc-event, .rbc-slot-selecting .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-slot-selecting .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #265985;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto #3b99fc;
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px #33333380;
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rbc-event-continues-later {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-row {
  flex-direction: row;
  display: flex;
}

.rbc-row-segment {
  padding: 0 1px 1px;
}

.rbc-selected-cell {
  background-color: #0000001a;
}

.rbc-show-more {
  z-index: 4;
  color: #3174ad;
  background-color: #ffffff4d;
  height: auto;
  font-size: 85%;
  font-weight: bold;
  line-height: normal;
}

.rbc-show-more:hover, .rbc-show-more:focus {
  color: #265985;
}

.rbc-month-view {
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #ddd;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.rbc-month-header {
  flex-direction: row;
  display: flex;
}

.rbc-month-row {
  flex-direction: column;
  flex: 1 0 0;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #ddd;
}

.rbc-date-cell {
  text-align: right;
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
}

.rbc-date-cell.rbc-now {
  font-weight: bold;
}

.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-bg {
  flex-direction: row;
  flex: 1 0 0;
  display: flex;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-overlay {
  z-index: 5;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  padding: 10px;
  position: absolute;
  box-shadow: 0 5px 15px #00000040;
}

.rbc-overlay > * + * {
  margin-top: 1px;
}

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px;
  padding: 2px 10px;
}

.rbc-agenda-view {
  flex-direction: column;
  flex: 1 0 0;
  display: flex;
  overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 100%;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  vertical-align: top;
  padding: 5px 10px;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  text-transform: lowercase;
  padding-left: 15px;
  padding-right: 15px;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: left;
  border-bottom: 1px solid #ddd;
  padding: 3px 5px;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}

.rbc-agenda-time-cell {
  text-transform: lowercase;
}

.rbc-agenda-time-cell .rbc-continues-after:after {
  content: " »";
}

.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: "« ";
}

.rbc-agenda-date-cell, .rbc-agenda-time-cell {
  white-space: nowrap;
}

.rbc-agenda-event-cell {
  width: 100%;
}

.rbc-time-column {
  flex-direction: column;
  min-height: 100%;
  display: flex;
}

.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #ddd;
  flex-flow: column;
  min-height: 40px;
  display: flex;
}

.rbc-time-gutter, .rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 10px;
  position: absolute;
  inset: 0;
}

.rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  border: 1px solid #265985;
  flex-flow: column wrap;
  align-items: flex-start;
  min-height: 20px;
  max-height: 100%;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.rbc-day-slot .rbc-background-event {
  opacity: .75;
}

.rbc-day-slot .rbc-event-label {
  flex: none;
  width: auto;
  padding-right: 5px;
}

.rbc-day-slot .rbc-event-content {
  word-wrap: break-word;
  flex: 1 1 0;
  width: 100%;
  height: 100%;
  min-height: 1em;
  line-height: 1;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}

.rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter {
  z-index: 10;
  background-color: #fff;
  border-right: 1px solid #ddd;
  margin-right: -1px;
  position: sticky;
  left: 0;
}

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
  flex: 1 0 0;
  min-width: auto;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}

.rbc-time-view-resources .rbc-header, .rbc-time-view-resources .rbc-day-bg {
  flex: 1 1 0;
  -ms-flex-preferred-size: 0 px;
  flex-basis: 0 px;
  width: 140px;
}

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;
}

.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  z-index: 10;
  color: #fff;
  background-color: #00000080;
  width: 100%;
  padding: 3px;
  font-size: 75%;
  position: absolute;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  border: 1px solid #ddd;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 0;
  display: flex;
}

.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
  text-align: right;
}

.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}

.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #ddd;
}

.rbc-time-view .rbc-allday-events {
  z-index: 4;
  position: relative;
}

.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}

.rbc-time-header {
  flex-direction: row;
  flex: none;
  display: flex;
}

.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #ddd;
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-left: 1px solid #ddd;
  border-right-width: 0;
}

.rbc-time-header > .rbc-row:first-child, .rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  border-left: 1px solid #ddd;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  display: flex;
}

.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
}

.rbc-time-content {
  border-top: 2px solid #ddd;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.rbc-time-content > .rbc-time-gutter {
  flex: none;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-time-content > .rbc-day-slot {
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}

.rbc-current-time-indicator {
  z-index: 3;
  pointer-events: none;
  background-color: #74ad31;
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
}

.nova-mobile {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.nova-mobile .content-centering-container {
  width: 400px;
  height: 100%;
  position: relative;
  left: calc(50% - 200px);
}

.nova-mobile .compact-menu-wrapper {
  left: 300px;
}

.nova-mobile .overlap-wrapper {
  background-color: #fff;
  border: 1px;
  width: 100%;
  height: 1868px;
  overflow: hidden;
}

.nova-mobile .overlap {
  width: 100%;
  height: 1568px;
  position: relative;
}

.nova-mobile .header-section {
  width: 100%;
  height: 925px;
  position: absolute;
  top: 0;
  left: 0;
}

.nova-mobile .overlap-group {
  background-color: #002e36;
  width: 100%;
  height: 925px;
  position: relative;
}

.nova-mobile .compact-menu {
  width: 306px;
  height: 141px;
  position: absolute;
  top: 77px;
  left: 48px;
}

.nova-mobile .text-wrapper {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 56px;
}

.nova-mobile .div {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 50px;
  left: 56px;
}

.nova-mobile .text-wrapper-2 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 90px;
  left: 56px;
}

.nova-mobile .FYS-logo {
  object-fit: cover;
  width: 39px;
  height: 141px;
  position: absolute;
  top: 0;
  left: 0;
}

.nova-mobile .text-wrapper-3 {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: right;
  width: 295px;
  height: 78px;
  font-family: Montserrat, Helvetica;
  font-size: 54px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 215px;
  left: 48px;
}

.nova-mobile .p {
  color: #ffd6af;
  letter-spacing: 0;
  width: 295px;
  height: 408px;
  font-family: Montserrat, Helvetica;
  font-size: 14px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 323px;
  left: 48px;
}

.nova-mobile .header-bg {
  background-color: #ffd6af;
  width: 100%;
  height: 1573px;
  position: absolute;
  top: 925px;
  left: 1px;
}

.nova-mobile .calendar-wrapper {
  object-fit: cover;
  width: 293px;
  height: 281px;
  font-family: Montserrat, Helvetica;
  font-size: 12px;
  position: absolute;
  top: 1093px;
  left: 47px;
}

.nova-mobile .h-1 {
  color: #002e36;
  letter-spacing: 0;
  width: 295px;
  height: 78px;
  font-family: Montserrat, Helvetica;
  font-size: 54px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 969px;
  left: 47px;
}

.nova-mobile .h-2 {
  color: #002e36;
  letter-spacing: 0;
  width: 295px;
  height: 78px;
  font-family: Montserrat, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 1044px;
  left: 47px;
}

.nebula {
  background-color: #002e36;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.nebula .div {
  background-color: #fff;
  border: 1px;
  width: 100%;
  height: 2200px;
  position: relative;
  overflow: hidden;
}

.nebula .nebula-top {
  width: 100%;
  height: 900px;
  position: absolute;
  top: 0;
  left: 0;
}

.nebula .overlap-group {
  background-color: #002e36;
  width: 100%;
  height: 900px;
  position: relative;
}

.nebula .compact-menu {
  width: 285px;
  height: 168px;
  position: absolute;
  top: 48px;
  left: 72px;
}

.nebula .text-wrapper {
  color: #ffd6af;
  letter-spacing: 0;
  width: 188px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 91px;
}

.nebula .text-wrapper-2 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 109px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 65px;
  left: 91px;
}

.nebula .text-wrapper-3 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 147px;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 115px;
  left: 91px;
}

.nebula .FYS-logo {
  object-fit: cover;
  width: 56px;
  height: 168px;
  position: absolute;
  top: 0;
  left: 0;
}

.nebula .h-1 {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: right;
  height: 146px;
  font-family: Montserrat, Helvetica;
  font-size: 120px;
  font-weight: 200;
  line-height: 1;
  position: absolute;
  top: 59px;
  right: 75px;
}

.nebula .p {
  color: #ffd6af;
  letter-spacing: 0;
  width: 1197px;
  height: 352px;
  font-family: Montserrat, Helvetica;
  font-size: 23px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 268px;
  left: 72px;
}

.nebula .overlap {
  background-color: #ffd6af;
  width: 100%;
  height: 2483px;
  position: absolute;
  top: 900px;
  left: 0;
}

.nebula .text-wrapper-4 {
  color: #002e36;
  letter-spacing: 0;
  text-align: right;
  height: 146px;
  font-family: Montserrat, Helvetica;
  font-size: 120px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 72px;
  left: 850px;
}

.nebula .click-here {
  color: #002e36;
  letter-spacing: 0;
  text-align: left;
  height: 146px;
  font-family: Montserrat, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 145px;
  left: 156px;
}

.nebula .calendar-wrapper {
  width: 1188px;
  height: 1480px;
  font-family: Montserrat, Helvetica;
  position: absolute;
  top: 280px;
  left: 156px;
}

.nebula-mobile {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.nebula-mobile .overlap-wrapper {
  background-color: #fff;
  border: 1px;
  width: 100%;
  height: 2050px;
  overflow: hidden;
}

.nebula-mobile .content-centering-container {
  width: 400px;
  height: 100%;
  position: relative;
  left: calc(50% - 200px);
}

.nebula-mobile .overlap {
  width: 100%;
  height: 1568px;
  position: relative;
}

.nebula-mobile .header-section {
  width: 100%;
  height: 975px;
  position: absolute;
  top: 0;
  left: 0;
}

.nebula-mobile .overlap-group {
  background-color: #002e36;
  width: 100%;
  height: 975px;
  position: relative;
}

.nebula-mobile .compact-menu {
  width: 306px;
  height: 141px;
  position: absolute;
  top: 77px;
  left: 48px;
}

.nebula-mobile .text-wrapper {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 56px;
}

.nebula-mobile .div {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 50px;
  left: 56px;
}

.nebula-mobile .text-wrapper-2 {
  color: #ffd6af;
  letter-spacing: 0;
  width: 244px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 90px;
  left: 56px;
}

.nebula-mobile .FYS-logo {
  object-fit: cover;
  width: 39px;
  height: 141px;
  position: absolute;
  top: 0;
  left: 0;
}

.nebula-mobile .text-wrapper-3 {
  color: #ffd6af;
  letter-spacing: 0;
  text-align: right;
  width: 295px;
  height: 78px;
  font-family: Montserrat, Helvetica;
  font-size: 44px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 225px;
  left: 48px;
}

.nebula-mobile .p {
  color: #ffd6af;
  letter-spacing: 0;
  width: 295px;
  height: 408px;
  font-family: Montserrat, Helvetica;
  font-size: 12px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 323px;
  left: 48px;
}

.nebula-mobile .header-bg {
  background-color: #ffd6af;
  width: 100%;
  height: 1543px;
  position: absolute;
  top: 975px;
  left: 1px;
}

.nebula-mobile .calendar-wrapper {
  object-fit: cover;
  width: 80%;
  height: 2500px;
  font-family: Montserrat, Helvetica;
  position: absolute;
  top: 1093px;
  left: 47px;
}

.nebula-mobile .h-1 {
  color: #002e36;
  letter-spacing: 0;
  width: 295px;
  height: 78px;
  font-family: Montserrat, Helvetica;
  font-size: 54px;
  font-weight: 200;
  line-height: normal;
  position: absolute;
  top: 979px;
  left: 47px;
}

/*# sourceMappingURL=index.5aa68404.css.map */

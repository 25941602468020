.about-us-page-mobile {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.about-us-page-mobile .div {
  background-color: #ffffff;
  border: 1px none;
  height: 9906px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.about-us-page-mobile .content-centering-container {
  width: 400px;
  height: 100%;
  left: calc(50% - 200px);
  position: relative;
}

.about-us-page-mobile .header-section {
  height: 925px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.about-us-page-mobile .overlap-group {
  background-color: #002e36;
  height: 925px;
  position: relative;
  width: 100%;
}

.about-us-page-mobile .compact-menu {
  height: 141px;
  left: 48px;
  position: absolute;
  top: 77px;
  width: 306px;
}

.about-us-page-mobile .text-wrapper {
  color: #ffd6af;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 56px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 11px;
  width: 244px;
}

.about-us-page-mobile .text-wrapper-2 {
  color: #ffd6af;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 56px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 50px;
  width: 244px;
}

.about-us-page-mobile .text-wrapper-3 {
  color: #ffd6af;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 56px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 90px;
  width: 244px;
}

.about-us-page-mobile .FYS-logo {
  height: 141px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 39px;
}

.about-us-page-mobile .h-1 {
  color: #ffd6af;
  font-family: "Montserrat", Helvetica;
  font-size: 60px;
  font-weight: 200;
  height: 78px;
  left: 84px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 265px;
  width: 259px;
}

.about-us-page-mobile .p {
  color: #ffd6af;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 304px;
  left: 48px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 393px;
  width: 295px;
}

.about-us-page-mobile .founders {
  height: 3127px;
  left: 1px;
  position: absolute;
  top: 6679px;
  width: 100%;
}

.about-us-page-mobile .overlap {
  background-color: #76a5af;
  height: 3227px;
  position: relative;
  width: 100%;
}

.about-us-page-mobile .text-wrapper-4 {
  color: #002e36;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-weight: 200;
  height: 44px;
  left: 48px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 35px;
}

.about-us-page-mobile .overlap-group-2 {
  height: 688px;
  left: 48px;
  position: absolute;
  top: 103px;
  width: 299px;
}

.about-us-page-mobile .lauren {
  height: 688px;
  left: 0;
  position: absolute;
  top: 0;
  width: 299px;
}

.about-us-page-mobile .siddh {
  height: 688px;
  left: 0;
  position: absolute;
  top: 0;
  width: 299px;
}

.about-us-page-mobile .fiddi {
  height: 688px;
  left: 0;
  position: absolute;
  top: 0;
  width: 299px;
}

.about-us-page-mobile .text-wrapper-5 {
  color: #002e36;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 300;
  height: 29px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 349px;
  width: 295px;
}

.about-us-page-mobile .profile-pic {
  height: 331px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 293px;
}

.about-us-page-mobile .lorem-ipsum-dolor {
  color: #002e36;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 300px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 388px;
  width: 293px;
}

.about-us-page-mobile .aric {
  height: 688px;
  left: 48px;
  position: absolute;
  top: 1577px;
  width: 299px;
}
.about-us-page-mobile .nikki {
  height: 688px;
  left: 48px;
  position: absolute;
  top: 1747px;
  width: 299px;
}
.about-us-page-mobile .annika {
  height: 688px;
  left: 48px;
  position: absolute;
  top: 1427px;
  width: 299px;
}

.about-us-page-mobile .bilal {
  height: 688px;
  left: 48px;
  position: absolute;
  top: 2478px;
  width: 299px;
}
.about-us-page-mobile .nav {
  height: 688px;
  left: 48px;
  position: absolute;
  top: 2428px;
  width: 299px;
}

.about-us-page-mobile .birinder {
  height: 688px;
  left: 48px;
  position: absolute;
  top: 929px;
  width: 299px;
}
.about-us-page-mobile .samiksha {
  height: 688px;
  left: 48px;
  position: absolute;
  top: 779px;
  width: 299px;
}
.about-us-page-mobile .serena {
  height: 688px;
  left: 48px;
  position: absolute;
  top: 779px;
  width: 299px;
}

.about-us-page-mobile .team-nebula {
  height: 2327px;
  left: 0;
  position: absolute;
  top: 925px;
  width: 100%;
}

.about-us-page-mobile .overlap-2 {
  background-color: #ffd6af;
  height: 2327px;
  position: relative;
  width: 100%;
}

.about-us-page-mobile .team-nova {
  height: 3427px;
  left: 1px;
  position: absolute;
  top: 3252px;
  width: 100%;
}

.about-us-page-mobile .overlap-3 {
  background-color: #f4d8c8c8;
  height: 3427px;
  position: relative;
  width: 100%;
}

.main-page {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.main-page  a {
  color: #ffd6af;
  text-decoration: none;
}


.main-page .div {
  background-color: #ffffff;
  border: 1px none;
  height: 4490px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.main-page .overlap {
  height: 2060px;
  left: 0;
  position: absolute;
  top: 810px;
  width: 100%;
}

.main-page .our-classes {
  height: 1253px;
  left: 0;
  position: absolute;
  top: 807px;
  width: 100%;
}

.main-page .overlap-group {
  background-color: #ffffff;
  height: 1253px;
  position: relative;
  width: 100%;
}

.main-page .text-wrapper {
  color: #002e36;
  font-family: "Montserrat", Helvetica;
  font-size: 120px;
  font-weight: 200;
  height: 146px;
  left: 750px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 83px;
}

.main-page .lorem-ipsum-dolor {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 725px;
  left: 72px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 353px;
  width: 1198px;
}

.main-page .welcome-section {
  height: 810px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.main-page .overlap-2 {
  background-color: #ffd6af;
  height: 810px;
  position: relative;
  width: 100%;
}

.main-page .text-wrapper-2 {
  color: #002e36;
  font-family: "Montserrat", Helvetica;
  font-size: 120px;
  font-weight: 200;
  height: 190px;
  left: 600px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 130px;
  width: 798px;
}

.main-page .welcome-message {
  color: #002e36;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-weight: 200;
  left: 600px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 360px;
  width: 798px;
}

.main-page .translucent-logo {
  height: 1080px;
  left: 110px;
  object-fit: cover;
  position: absolute;
  top: 60px;
  width: 340px;
  /* color: #000000; */
  stroke-width: 10px;
  opacity: 50%;
}

.main-page .header-section {
  background-color: #002e36;
  height: 810px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.main-page .selection-group {
  height: 137px;
  position: absolute;
  top: 48px;
  right: 50px;
  width: 566px;
}

.main-page .text-wrapper-3 {
  color: #ffd6af;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-style: italic;
  font-weight: 200;
  height: 37px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 15px;
  width: 560px;
}

.main-page .text-wrapper-4 {
  color: #ffd6af;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 37px;
  left: 270px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 65px;
  width: 290px;
}

.main-page .text-wrapper-5 {
  color: #ffd6af;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 37px;
  left: 240px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 115px;
  width: 318px;
}

.main-page .compact-menu {
  height: 168px;
  left: 72px;
  position: absolute;
  top: 48px;
  width: 524px;
}

.main-page .text-wrapper-6 {
  color: #ffd6af;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 91px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 15px;
  width: 397px;
}

.main-page .text-wrapper-7 {
  color: #ffd6af;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 91px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 65px;
  width: 417px;
}

.main-page .text-wrapper-8 {
  color: #ffd6af;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 91px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 115px;
  width: 427px;
}

.main-page .FYS-logo {
  height: 168px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 56px;
}

.main-page .title {
  height: 377px;
  left: 72px;
  position: absolute;
  top: 315px;
  width: 1095px;
}

.main-page .future-for-young {
  color: #ffd6af;
  font-family: "Montserrat", Helvetica;
  font-size: 135px;
  font-weight: 200;
  height: 330px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.main-page .text-wrapper-9 {
  color: #ffd6af;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-weight: 100;
  height: 37px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 340px;
}

.main-page .parent-testimonials {
  height: 810px;
  left: 0;
  position: absolute;
  top: 2870px;
  width: 100%;
}

.main-page .overlap-3 {
  background-color: #f4d8cd;
  height: 810px;
  position: relative;
  width: 100%;
}

.main-page .text-wrapper-10 {
  color: #002e36;
  font-family: "Montserrat", Helvetica;
  font-size: 90px;
  font-weight: 200;
  height: 220px;
  left: 72px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 520px;
  width: 600px;
}

.main-page .text-wrapper-11 {
  color: #002e36;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 120px;
  left: 750px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 51px;
  width: 575px;
}

.main-page .text-wrapper-12 {
  color: #002e36;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 120px;
  left: 750px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 233px;
  width: 575px;
}

.main-page .text-wrapper-13 {
  color: #002e36;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 120px;
  left: 750px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 597px;
  width: 575px;
}

.main-page .text-wrapper-14 {
  color: #002e36;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 120px;
  left: 750px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 385px;
  width: 575px;
}

.main-page .socials {
  height: 810px;
  left: 0;
  position: absolute;
  top: 3680px;
  width: 100%;
}

.main-page .overlap-4 {
  background-color: #002e36;
  height: 810px;
  position: relative;
  width: 100%;
}

.main-page .text-wrapper-15 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 90px;
  font-weight: 200;
  height: 110px;
  left: 326px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 130px;
}

.main-page .text-wrapper-16 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 45px;
  font-weight: 300;
  height: 55px;
  left: 178px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 535px;
}
.socials  a {
  color: #ffffff;
  text-decoration: none;
}
.socials .text-wrapper-17 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-weight: 300;
  height: 55px;
  left: 600px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 645px;
  text-decoration: underline;
}

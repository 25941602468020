.nova-mobile {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .nova-mobile .content-centering-container {
    width: 400px;
    height: 100%;
    left: calc(50% - 200px);
    position: relative;
  }

  .nova-mobile .compact-menu-wrapper {
    /* left: calc(50% - 200px); */
    left: 300px;
    /* width: 350px;
    height: 350px; */
  }
  
  .nova-mobile .overlap-wrapper {
    background-color: #ffffff;
    border: 1px none;
    height: 1868px;
    overflow: hidden;
    width: 100%;
  }
  
  .nova-mobile .overlap {
    height: 1568px;
    position: relative;
    width: 100%;
  }
  
  .nova-mobile .header-section {
    height: 925px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .nova-mobile .overlap-group {
    background-color: #002e36;
    height: 925px;
    position: relative;
    width: 100%;
  }
  
  .nova-mobile .compact-menu {
    height: 141px;
    left: 48px;
    position: absolute;
    top: 77px;
    width: 306px;
  }
  
  .nova-mobile .text-wrapper {
    color: #ffd6af;
    font-family: "Montserrat", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 56px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 11px;
    width: 244px;
  }
  
  .nova-mobile .div {
    color: #ffd6af;
    font-family: "Montserrat", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 56px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 50px;
    width: 244px;
  }
  
  .nova-mobile .text-wrapper-2 {
    color: #ffd6af;
    font-family: "Montserrat", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 56px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 90px;
    width: 244px;
  }
  
  .nova-mobile .FYS-logo {
    height: 141px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 39px;
  }
  
  .nova-mobile .text-wrapper-3 {
    color: #ffd6af;
    font-family: "Montserrat", Helvetica;
    font-size: 54px;
    font-weight: 200;
    height: 78px;
    left: 48px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 215px;
    width: 295px;
  }
  
  .nova-mobile .p {
    color: #ffd6af;
    font-family: "Montserrat", Helvetica;
    font-size: 14px;
    font-weight: 200;
    height: 408px;
    left: 48px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 323px;
    width: 295px;
  }
  
  .nova-mobile .header-bg {
    background-color: #ffd6af;
    height: 1573px;
    left: 1px;
    position: absolute;
    top: 925px;
    width: 100%;
  }
  
  .nova-mobile .calendar-wrapper {
    height: 281px;
    left: 47px;
    object-fit: cover;
    position: absolute;
    top: 1093px;
    width: 293px;
    /* width: calc(75vw); */
    font-family: "Montserrat", Helvetica;
    font-size: 12px;
  }
  
  .nova-mobile .h-1 {
    color: #002e36;
    font-family: "Montserrat", Helvetica;
    font-size: 54px;
    font-weight: 200;
    height: 78px;
    left: 47px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 969px;
    width: 295px;
  }

  .nova-mobile .h-2 {
    color: #002e36;
    font-family: "Montserrat", Helvetica;
    font-size: 18px;
    font-weight: 400;
    height: 78px;
    left: 47px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1044px;
    width: 295px;
  }

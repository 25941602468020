.about-us-page {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .about-us-page .div {
    background-color: #ffffff;
    border: 1px none;
    height: 9872px;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .about-us-page .content-centering-container {
    width: 1300px;
    height: 100%;
    left: calc(50% - 650px);
    position: relative;
  }
  
  .about-us-page .about-us-top {
    height: 810px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .about-us-page .overlap-group {
    background-color: #002e36;
    height: 810px;
    position: relative;
    width: 100%;
  }
  
  
  .about-us-page .FYS-logo {
    height: 168px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 56px;
  }
  
  .about-us-page .h-1 {
    color: #ffd6af;
    font-family: "Montserrat", Helvetica;
    font-size: 120px;
    font-weight: 200;
    height: 146px;
    left: 700px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 59px;
  }
  
  .about-us-page .p {
    color: #ffd6af;
    font-family: "Montserrat", Helvetica;
    font-size: 32px;
    font-weight: 200;
    height: 264px;
    left: 72px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 382px;
    width: 1107px;
  }
  
  .about-us-page .team-nebula {
    height: 2554px;
    left: 0;
    position: absolute;
    top: 810px;
    width: 100%;
  }
  
  .about-us-page .overlap {
    /* background-image: url(../../../static/img/group1-bg.svg); */
    /* background-size: 100% 100%; */
    background-color: #ffd6af;
    height: 2554px;
    position: relative;
    width: 100%;
  }
  
  .about-us-page .overlap-group-2 {
    height: 820px;
    left: 103px;
    position: absolute;
    top: 319px;
    width: 100%;
  }
  
  .about-us-page .sample-person {
    height: 820px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .about-us-page .profile-pic {
    height: 580px;
    left: 516px;
    object-fit: cover;
    position: absolute;
    top: 29px;
    width: 580px;
  }
  
  .about-us-page .text-wrapper-4 {
    color: #002e36;
    font-family: "Montserrat", Helvetica;
    font-size: 40px;
    font-weight: 400;
    height: 55px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 0;
    width: 450px;
  }
  
  .about-us-page .lorem-ipsum-dolor {
    color: #002e36;
    font-family: "Montserrat", Helvetica;
    font-size: 18px;
    font-weight: 400;
    height: 754px;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 66px;
    width: 450px;
  }
  
  .about-us-page .overlap-2 {
    height: 820px;
    left: 103px;
    position: absolute;
    top: 1055px;
    width: 100%;
  }
  
  .about-us-page .overlap-3 {
    height: 820px;
    left: 85px;
    position: absolute;
    top: 1791px;
    width: 100%;
  }
  
  .about-us-page .sample-person-2 {
    height: 820px;
    left: 85px;
    position: absolute;
    top: 2528px;
    width: 100%;
  }
  
  .about-us-page .text-wrapper-5 {
    color: #002e36;
    font-family: "Montserrat", Helvetica;
    font-size: 90px;
    font-weight: 200;
    height: 110px;
    left: 500px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 104px;
    width: 703px;
  }
  
  .about-us-page .team-nova {
    height: 3254px;
    left: 0;
    position: absolute;
    top: 3364px;
    width: 100%;
  }
  
  .about-us-page .overlap-4 {
    /* background-image: url(../../../static/img/nova-bg.svg);
    background-size: 100% 100%; */
    background-color: #f4d8c8;
    height: 3254px;
    position: relative;
    width: 100%;
  }
  
  .about-us-page .text-wrapper-6 {
    color: #002e36;
    font-family: "Montserrat", Helvetica;
    font-size: 90px;
    font-weight: 200;
    height: 110px;
    left: 500px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 123px;
    width: 703px;
  }
  
  .about-us-page .team-founders {
    height: 3254px;
    left: 0;
    position: absolute;
    top: 6618px;
    width: 100%;
  }
  
  .about-us-page .overlap-5 {
    /* background-image: url(../../../static/img/founders-bg.svg);
    background-size: 100% 100%; */
    background-color: #76a5af;
    height: 3254px;
    position: relative;
    width: 100%;
  }
  
  .about-us-page .text-wrapper-7 {
    color: #002e36;
    font-family: "Montserrat", Helvetica;
    font-size: 90px;
    font-weight: 200;
    height: 110px;
    left: 500px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 152px;
    width: 703px;
  }
  